import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Carousel } from "react-responsive-carousel"

const IndexPage = props => {
  return (
    <Layout pageName={"home"} showHeader={false}>
      <SEO title="Home" />
      <section id="main-slider" className="no-margin">
		<div className="row">
          
		  <div className="col-sm-1">
		  </div>
		   
		  <div className="col-sm-11">
		  <h4>...Formerly known as ajvdc.org. Rest assured, AJVDC has not changed.</h4>
		  </div>
		  

	    </div>
		
		
        <Carousel
          dynamicHeight={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={3000}
          transitionTime={500}
          showArrows={true}
          showThumbs={false}
          onChange={() => {}}
          onClickItem={() => {}}
          onClickThumb={() => {}}
        >

		  <div>
            <Image imageKey={"image10"} />
            <p className="legend">
              Street children in Goma city
            </p>
          </div>
		  <div>
            <Image imageKey={"image8"} />
            <p className="legend">
              Lobbying meeting, peace planning (Advocating)
            </p>
          </div>
		  <div>
            <Image imageKey={"image52"} />
            <p className="legend">
				?
			</p>
          </div>
          <div>
            <Image imageKey={"image53"} />
            <p className="legend">
				?
            </p>
          </div>
          <div>
            <Image imageKey={"image54"} />
            <p className="legend">
              Walking long distances of 6km or more to the lake to get water
            </p>
          </div>
		  <div>
            <Image imageKey={"image9"} />
            <p className="legend">
              Lobbying meeting, peace planning (Advocating)
            </p>
          </div>
          <div>
            <Image imageKey={"image55"} />
            <p className="legend">
              Walking long distances of 6km or more to the lake to get water
            </p>
          </div>
          <div>
            <Image imageKey={"image56"} />
            <p className="legend">
              Young girls walking long distances of 6km or more to the lake to get water
            </p>
          </div>
          <div>
            <Image imageKey={"image57"} />
            <p className="legend">
              Walking long distances of 6km or more to the lake to get water
            </p>
          </div>
		  <div>
            <Image imageKey={"image58"} />
            <p className="legend">
				Agribusiness training program
            </p>
          </div>
          <div>
            <Image imageKey={"image51"} />
            <p className="legend">
               Women, Orphans children in Inters displaced Camps.
            </p>
          </div>
          <div>
            <Image imageKey={"image11"} />
            <p className="legend">
              Women, Orphans children in internally displaced camps
            </p>
          </div>
          <div>
            <Image imageKey={"image12"} />
            <p className="legend">
				?????
			</p>
          </div>
          <div>
            <Image imageKey={"image13"} />
            <p className="legend">
              Young girl used as combatant with a negative armed group in Masisi
            </p>
          </div>
          <div>
            <Image imageKey={"image14"} />
            <p className="legend">
				Festival Amani (peace festival), Dancing for peace Singing for peace In Goma.
			</p>
          </div>
          <div>
            <Image imageKey={"image15"} />
            <p className="legend">
              Agribusiness training program
            </p>
          </div>
          <div>
            <Image imageKey={"image16"} />
            <p className="legend">
              Agribusiness training program
            </p>
          </div>
          <div>
            <Image imageKey={"image17"} />
            <p className="legend">
              Child care, volunteering in Inters Displaced Camps
            </p>
          </div>
          <div>
            <Image imageKey={"image18"} />
            <p className="legend">
				DRC natural resources and environment (water, forests, volcano…)
			</p>
          </div>
          <div>
            <Image imageKey={"image19"} />
            <p className="legend">
              Youth nature conservation and environment protection
            </p>
          </div>
          <div>
            <Image imageKey={"image20"} />
            <p className="legend">
				DDR Camp in Bweremana, Masisi ( Demobilisation Desarment and Reintegration program) more than 400 youth  ex-combatants gathered waiting to be reintegrated in civil life. 
            </p>
          </div>
          <div>
            <Image imageKey={"image21"} />
            <p className="legend">
				DDR Camp in Bweremana, Masisi ( Demobilisation Desarment and Reintegration program) more than 400 youth  ex-combatants gathered waiting to be reintegrated in civil life. 
			</p>
          </div>
          <div>
            <Image imageKey={"image22"} />
            <p className="legend">
              Young girl used as combatant with a negative armed group in Masisi
            </p>
          </div>
          <div>
            <Image imageKey={"image23"} />
            <p className="legend">
              A gorrila in the DRC
            </p>
          </div>
          <div>
            <Image imageKey={"image24"} />
            <p className="legend">
                Masisi displaced camps
            </p>
          </div>
          <div>
            <Image imageKey={"image25"} />
            <p className="legend">
				Place to build a City of peace center
			</p>
          </div>
          <div>
            <Image imageKey={"image26"} />
            <p className="legend">
				DRC natural resources and environment (water, forests, volcano…)
			</p>
          </div>
          <div>
            <Image imageKey={"image27"} />
            <p className="legend">
              ????
            </p>
          </div>
          <div>
            <Image imageKey={"image28"} />
            <p className="legend">
              Boys and girls skills training program (Entrepreneurship)
            </p>
          </div>
          <div>
            <Image imageKey={"image29"} />
            <p className="legend">
              Girls sewing training program ( empowerment program) 
            </p>
          </div>
		  <div>
            <Image imageKey={"image30"} />
            <p className="legend">
               ???
            </p>
          </div>
        </Carousel>
      </section>

      <section id="donate" className="emerald">
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-centered">
              <a
                href="https://www.paypal.me/AJVDC"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://www.paypalobjects.com/webstatic/en_US/i/btn/png/btn_donate_cc_147x47.png"
                  alt="Donate"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="emerald">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="media">
                <div className="pull-left">
                  <i className="fa fa-child fa-2x icon-md" />
                </div>
                <div className="media-body">
                  <h3 className="media-heading">Youth</h3>
                  <p>
                    Youth has power to change, to work for sustainable peace if
                    they are educated and getting involved in peacebuilding
                    programs. We believe that youth can play a great role for
                    peace and development in our country D.R.Congo. Nowadays
                    youth are playing double role, as victims and actors, in the
                    disasters if war and conflicts in Eastern Congo.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="media">
                <div className="pull-left">
                  <i className="fa fa-globe fa-2x icon-md" />
                </div>
                <div className="media-body">
                  <h3 className="media-heading">Congo</h3>
                  <p>
                    A vast country with immense economic resources, the
                    Democratic Republic of Congo (DR Congo) has until recently
                    been at the centre of what some observers call "Africa's
                    world war", with widespread civilian suffering the result.
                    The situation in the DRC remains volatile to this day.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="media">
                <div className="pull-left">
                  <i className="fa fa-eye fa-2x icon-md" />
                </div>
                <div className="media-body">
                  <h3 className="media-heading">Vision</h3>
                  <p>
                    Peace vision, AJVDC youth group is concerned on how to
                    support movements of peace reconstruction. They are planning
                    to conduct a peace education initiatives and social and
                    economic integration program, focusing on disadvantaged
                    people, displaced people, women victims of rape, children
                    former soldiers, youth ex-rebels, and returnee refugee. We
                    envision mobilizing youth civic engagement to transform our
                    Cities to become Cities of Peace and Villages of peace.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="recent-works" className="container">
        <div className="row">
          <div className="col-md-2">
            <h3>Our Latest News</h3>
            <p>
              See the latest news from our{" "}
              <a href="https://www.twitter.com/LeonSimweragi">twitter</a>.
            </p>
            <p className="gap" />
          </div>
          <div className="col-md-10">
            <a
              className="twitter-timeline"
              href="https://twitter.com/LeonSimweragi?ref_src=twsrc%5Etfw"
            >
              Tweets by LeonSimweragi
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
